const pricingPlans = [
    {
        title: "Instant EV Delivery",
        parsent: 10,
        deliveryMode: "EV Bikes",
        description: "Quick and eco-friendly local deliveries.",
        features: ["Real-time tracking", "Ad-hoc bookings", "Carbon footprint reduction"],
        price: 1999
    },
    {
        title: "Scalable Tempo Rentals",
        parsent: 15,
        deliveryMode: "Tempo",
        description: "Flexible contracts for all business sizes.",
        features: ["Monthly contracts", "Dedicated support", "Nationwide service"],
        price: 4999
    },
    {
        title: "Nationwide Movers",
        parsent: 20,
        deliveryMode: "Packers ",
        description: "Seamless logistics across India.",
        features: ["End-to-end service", "Secure handling", "Customizable options"],
        price: 9999
    }
];

export default pricingPlans;
