
/* Single image */
import moreab1 from '../images/home/More About Us 1.png'
import moreab2 from '../images/home/More About us 2.png'
import moreab3 from '../images/home/More About us 4.png'

import evbike from '../images/about/evbike.png'
import tempo from '../images/about/tempooooooo1.png'
import dron from '../images/about/dron.png'
import packmove from '../images/about/packmove.png'
import allin from '../images/about/allin.png'

const Services = [
    {
        id: 1,
        image: evbike,
        simag: evbike,
        // iconName:'flaticon-road-with-broken-line',
        subtitle: 'Transport',
        title: 'EV Bikes for Instant Delivery Key Benefits',
        description: 'We offer fast, eco-friendly deliveries for food, groceries, and small parcels, ensuring efficiency with a reduced carbon footprint.',
        slug: 'Road-Freight-Service'
    },
    {
        id: 2,
        image: tempo,
        simag: tempo,
        // iconName: 'flaticon-air-plane',
        subtitle: 'Transport',
        title: 'Flexible Tempo Services for B2B and B2C Needs',
        description: 'We provide regular schedules, seamless supply chain integration, and reliable service for consistent delivery.',
        slug: 'Intermodal-Shipping'
    },
    {
        id: 3,
        image: dron,
        simag: dron,
        // iconName: 'flaticon-boat-with-containers',
        subtitle: 'Transport',
        title: 'Drone Delivery for Faster Service Key Benefits ',
        description: 'A time-saving, high-tech solution perfect for critical deliveries and modern needs.',
        slug: 'Maritime-transportation'
    },
    {
        id: 4,
        image: packmove,
        simag: packmove,
        // iconName: 'flaticon-boat-with-containers',
        subtitle: 'Transport',
        title: 'Packers and Movers ',
        description: 'Efficient and tech-driven, our Packers and Movers service ensures timely, safe delivery of your belongings with advanced tracking.',
        slug: 'Mari5time-transportation'
    },
    {
        id: 5,
        image: allin,
        simag: allin,
        // iconName: 'flaticon-boat-with-containers',
        subtitle: 'Transport',
        title: ' All India Parcel ',
        description: 'Fast, safe, and tech-driven parcel delivery across India with real-time tracking for peace of mind.',
        slug: 'Maritime-transportation'
    },
  
   


];
export default Services;